let BASE_URL = ''
const TIME_OUT = 180000

if (process.env.NODE_ENV === 'development') {
  BASE_URL = process.env.VUE_APP_DEVELOPMENT_BASE_URL
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = process.env.VUE_APP_PRODUCTION_BASE_URL
} else {
  BASE_URL = ''
}

export { BASE_URL, TIME_OUT }
